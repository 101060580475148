.synthese-choix-puissance {
    margin-bottom: 30px;

    h1 {
        margin-bottom: 20px;
    }

    .form-row {
        grid-template-columns: 270px 300px 230px;

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        .mobile-group {
            display: flex;
            align-items: center;

            .puissance {
                color: var(--statut-color);
                line-height: 30px;
                font-weight: bold;
                font-size: 16px;
                margin-left: 10px;
                width: 270px;
                flex: 1;
            }

            .input-zone {
                flex: 1;

                ng-select {
                    width: 100%;
                }
            }
        }

        button {
            background-color: var(--background-primary);
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            border-radius: 5px;

            fa-icon {
                margin-left: 10px;
            }
        }
    }
}

.puissance-warning-modal {
    max-width: 600px;

    .image {
        width: 80px;
    }
    .title {
        margin: 20px 0;
    }
    .actions {
        margin-top: 20px;

        .btn {
            width: 150px;
        }
    }
}

.mobile-group {
    margin-right: 10px;
    > p {
        height: 50px;
        line-height: 50px !important;
    }
}

.red {
    color: var(--montant-economise);
    font-weight: bold;
}

.info {
    font-size: 13px;
}
