@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /*h1 {*/
    /*    !*text-align: center;*!*/
    /*    !*font-size: 28px;*!*/
    /*    !*font-weight: 700;*!*/
    /*    !*color: rgb(51, 51, 51)*!*/
    /*}*/
    /*h2 {*/
    /*    text-align: center;*/
    /*    font-size: 17px;*/
    /*    font-weight: 700;*/
    /*    color: rgb(51, 51, 51)*/
    /*}*/
    /*h3 {*/
    /*    text-align: center;*/
    /*    font-size: 15px;*/
    /*    font-weight: 700;*/
    /*    color: rgb(51, 51, 51)*/
    /*}*/
    /*h4 {*/
    /*    font-size: 13px;*/
    /*    font-weight: 700;*/
    /*    color: rgb(51, 51, 51)*/
    /*}*/
    /*h6 {*/
    /*    font-weight: bold;*/
    /*}*/
    /*p {*/
    /*    color: rgb(51, 51, 51)*/
    /*}*/
    /*a {*/
    /*    text-decoration: underline;*/
    /*}*/

}
